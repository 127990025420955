/* eslint-disable no-irregular-whitespace */
import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';
import Banner from '../__Banner';
import BannerImg from 'imgs/1d1d/pc/banner.contact.png';

import MapImg from 'imgs/1d1d/pc/contact.map.png';

const Map = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  padding: 0.36rem 0;
  > img {
    width: 80%;
    margin: 0 auto;
  }
`;
const Title = styled.p`
  background: #fff;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  font-size: 0.26rem;
  color: #636363;
`;
const Loc = styled.section`
  background: #fff;
  padding: 0.68rem 0.76rem 0.82rem;
  .info {
    > h2 {
      color: #2c2c2c;
      font-size: 0.28rem;
      margin-bottom: 0.29rem;
      font-weight: bold;
    }
    p {
      display: flex;
      > span {
        font-size: 0.2rem;
        color: #636363;
        margin-right: 0.2rem;
      }
    }
  }
`;

export default function Contact() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="联系我们" h2s={['contact us']} />
      <Title>截至2019年6月,壹点壹滴在全国共加盟20000+园所</Title>
      <Map>
        <img data-aos="fade-up" src={MapImg} alt="中国地图" />
      </Map>
      <Loc>
        <div className="info" data-aos="fade-right">
          <h2>壹点壹滴总部联系方式</h2>
          {/* <p>
            <span>战略合作:</span>
            <span>info@1d1d100.com</span>
          </p>
          <p>
            <span>人员招聘:</span>
            <span>hr@1d1d100.com</span>
          </p>
          <p>
            // <span>热线电话:</span>
            // <span>400-666-7689</span>
          </p> */}
          <p>
            <span>邮    箱:</span>
            <span>scb@1d1d100.com</span>
          </p>
          <p>
            <span>地    址:</span>
            <span>北京市海淀区农大南路1号院2A号楼</span>
          </p>
          {/* <p>
            <span>邮    编:</span>
            <span>100008</span>
          </p> */}
        </div>
      </Loc>
    </Container>
  );
}
